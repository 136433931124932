@import './scss/variables.scss';
@import './scss/utils.scss';

:root {
  @include set-colors();
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 18px;

  @include respond-up-to('sm') {
    font-size: 12px;
  }
}

body {
  background-color: #fffdf1;
  color: #000421;
  margin: 0;
  padding: 1rem;
}

h1 {
  font-size: 3rem;

  @include respond-up-to('sm') {
    font-size: 1.5rem;
  }
}

a {
  color: #000421;
  transition: all 150ms ease;

  &:hover {
    color: #fd3f27;
  }
}

.text-center {
  text-align: center;
}

p {
  &.lead {
    font-size: 1.25rem;
  }
}

a {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

form {
  width: 100%;

  button.btn {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fd3f27;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 2rem;
    width: 25%;
    margin: 0 auto;
    margin-top: 2rem;
    transition: all 150ms ease;

    &:hover {
      cursor: pointer;
      background: lighten(#fd3f27, 5%);
    }

    &:active {
      background: darken(#fd3f27, 5%);
    }

    &[disabled] {
      background: lighten(#fd3f27, 10%);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    gap: 1rem;

    &.invalid {
      input {
        outline: 0.25rem solid #ff4229;

        &:focus {
          outline: 0.25rem solid #ff4229;
        }
      }
    }

    .hints {
      position: absolute;
      top: 100%;

      div {
        padding: 1rem 2rem;
      }
    }

    label {
      margin-bottom: 0.5rem;
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
    }

    input {
      font-size: 1rem;
      outline: none;
      background: #fffdf1;
      box-shadow:
        20px 20px 60px #d9d7cd,
        -20px -20px 60px #ffffff;
      width: 100%;
      border-radius: 2rem;
      padding-left: 2rem;
      padding-top: 1rem;
      padding-right: 5rem;
      padding-bottom: 1rem;
      font-size: 1.5rem;
    }

    button {
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0.125rem;
      right: 0.125rem;
      background: #fd3f27;
      color: white;
      font-size: 1.5rem;
      padding: 1rem;
      height: 4rem;
      width: 4rem;
      border-radius: 100%;
    }

    input,
    textarea {
      border: none;
      transition: all 150ms ease;
      outline: 0px solid #0fdeff00;

      &:focus {
        outline: 0.25rem solid #0fdeffff;
      }
    }
  }
}
